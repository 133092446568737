var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settingProduct"}},[_c('v-container',[_c('v-row',[_c('v-container',[_c('h2',[_vm._v(" ใบผลิตวัตถุดิบ ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"items-per-page":10,"search":_vm.searchTxt},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"ค้นหา","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"info","dark":""},on:{"click":_vm.showClick}},[_vm._v(" "+_vm._s(_vm.showTxt)+" ")]),_c('v-btn',{staticClass:"my-3",attrs:{"color":"primary","dark":""},on:{"click":_vm.addLine}},[_vm._v(" เพิ่มรายการ ")])],1)],1)]},proxy:true},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"info--text",on:{"click":function($event){return _vm.showDetail(item.production_id)}}},[_vm._v(" mdi-clipboard-check-outline ")])]}},{key:"item.production_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.production_date,"DD/MM/YYYY")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3 primary--text",on:{"click":function($event){return _vm.deleteDocument(item)}}},[_vm._v(" mdi-delete-forever ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 1)?_c('v-icon',{staticClass:"info--text"},[_vm._v(" mdi-loading ")]):_vm._e(),(item.status === 2)?_c('v-icon',{staticClass:"info--text"},[_vm._v(" mdi-check-bold ")]):_vm._e(),(item.status === 0)?_c('v-icon',{staticClass:"secondary--text"},[_vm._v(" mdi-close-thick ")]):_vm._e()]}}])})],1)],1)],1),_c('dialogEdit',{attrs:{"modal":_vm.showDialogEdit,"item":_vm.itemDialogEdit,"itemIndex":_vm.itemIndexDialogEdit,"itemIndexViewer":_vm.itemIndexDialogViewer},on:{"close":_vm.closeDialogEdit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }